<template>
  <!-- star -->
  <div class="homepage_wrap">
    <div class="banner_nav">
      <div>
        <div
            style="
            display: flex;
            align-items: center;
            justify-content: space-around;
          "
        >
          <div style="width: 75%; margin-top: 10px">
            <div style="display: flex; align-items: center">
              <img
                  style="width: 8%; height: 5%; margin-left: 5%"
                  src="../assets/img/logo111.png"
                  alt=""
              />
              <div
                  style="
                  color: #fff;
                  font-size: 20px;
                  font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi, ChunkFiveEx;
                "
              >
                河 北 中 正 <br/>
                HeBeiZhongZheng
              </div>
            </div>
          </div>
          <div>
            <ul class="nav">
              <li v-for="(item, index) in navData" :key="index" class="nav-li">
                <router-link :to="item.url">{{ item.title }}</router-link>
                <ul class="nav-children" v-if="item.children">
                  <li v-for="(child, index) in item.children" :key="index">
                    <router-link :to="child.url">{{ child.title }}</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="banner_title_center">
        <p>{{ imageurls[currentIndex] }}</p>
        <div>科技创新引领产业发展 <span>·</span>产教融合共育技术人才</div>
      </div>
    </div>

    <div class="swiper" style="width: 100vw; margin: 0 auto">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/img/sybj/lb1.jpg"/>
          </div>
          <div class="swiper-slide">
            <img src="../assets/img/sybj/lb2.jpg" />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <!--  -->

    <!-- 最新资讯 -->
    <div class="news">
      <div class="news_cont">
        <!-- left -->
        <div class="news_cont_left">
          <h1>最新资讯</h1>
          <div class="news_bg">
            <div class="news_cont_left_img">
              <img src="../assets/img/news_img1.png" alt=""/>
            </div>
            <div class="news_cont_left_text">
              <h1>
                交通运输部办公厅关于做好《机动车驾驶员培训管理规定》贯彻实施工作的通知
              </h1>
              <p>2023-05-30</p>
              <p>
                机动车驾驶培训关乎人民群众学驾需求，关乎道路交通安全发展基础，是交通运输系统重要服务领域之一
              </p>
            </div>
          </div>
        </div>
        <!-- right -->
        <div class="news_cont_right">
          <div class="news_cont_right_text">
            <div
                class="news_cont_right_text_item news_cont_right_text_hr"
                v-for="(item, index) in newList"
                :key="index"
                @click="newsArticle(index)"
            >
              <h1>{{ item.title }}</h1>
              <p>{{ item.time }}</p>
              <p>
                {{ item.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 关于企业 -->
    <div class="AboutEnterprises">
      <div class="AboutEnterprises_cont">
        <!-- top -->
        <div class="AboutEnterprises_cont_top">
          <!-- top_left -->
          <div class="AboutEnterprises_cont_top_left">
            <h1>关于我们</h1>
            <p>
              经营范围包括信息系统集成服务；软件开发；信息技术咨询服务；数据处理和存储服务；
              电子产品，指纹识别设备，计算机、软件及辅助设备批发、零售。（法律、法规规定需专项许可者，未经许可不得开展经营活动）
              公司理念: 高品质、优服务、低价位 ，经营理念:
              以人为本，守信经营，明码实价。公司宗旨:
              以身作则为榜样，身先士谋发展。旗下“中正易驾” 微信小程序
              提供了线上驾驶人培训计时功能，使得驾驶人更加便捷的进行驾驶业务的培训计时，更多功能。
              尽在其中，快快关注吧！
            </p>
            <router-link to="#">
              <div class="AboutEnterprises_cont_top_left_btn">
                <span>了解更多</span>
                <img src="../assets/img/guanyujiantou.png" alt=""/>
              </div>
            </router-link>
          </div>
          <!-- top_right -->
          <div class="AboutEnterprises_cont_top_right">
            <img src="../assets/img/guanyuyou.png" alt=""/>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="CooperativePartner" v-if="false">
      <div class="CooperativePartner_cont_center">
        <div class="CooperativePartner_cont_center_item">
          <span>资质荣誉</span>
        </div>
      </div>
      <div class="CooperativePartner_cont_bottom">
        <div
            v-for="(item, index) in logoList"
            :key="index"
            class="CooperativePartner_cont_bottom_logo"
        >
          <img :src="item.logo" alt=""/>
          <div
              style="
              text-align: center;
              background: lightgray;
              position: relative;
              top: -45px;
              width: 100%;
              height: 40px;
              line-height: 40px;
              opacity: 0.8;
            "
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import {newList, logoList, jituanList} from "./data";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

export default {
  name: "index",
  components: {
    footer2,
  },
  data() {
    return {
      navData: [
        {
          title: "首页",
          url: "/"
        },
        {
          title: "产品线",
          url: "/product/zhongzhengyijia",
          children: [
            {
              title: "中正易驾",
              url: "/product/zhongzhengyijia"
            },
            {
              title: "工单系统",
              url: "/product/workOrderSys"
            },
            {
              title: "中正信息科技",
              url: "/product/zhongzhengInfo"
            },
            {
              title: "教务系统",
              url: "/product/teachingSys"
            },
            {
              title: "活动营销小程序",
              url: "/product/activityMarketing"
            }
          ]
        },
        {
          title: "企业概况",
          url: "/"
        },
        {
          title: "联系我们",
          url: "/lianxi"
        },
      ],
      swiper: null,
      currentIndex: 0,
      imageurls: [
        "团队，忠诚，感恩，执行",
        "客户的事就是自己的事！",
        "真心诚意，尽心尽力",
      ],
      navList: [
        {title: "首页", url: "/"},
        {title: "集团概括", url: "/jituangaikuo"},
        {title: "集团产业", url: "/chanye"},
        {title: "集团新闻", url: "/xinwen"},
        // { title: "招聘详情", url: "/zhaopinxiangqing0_0" },
        {title: "联系我们", url: "/lianxi"},
        // { title: "招聘信息", url: "/zhaopinxinxi0" },
      ],
      jituanList,
      logoList,
      newList,
      bannerText: null,
    };
  },
  computed: {
    title() {
      return this.$store.state.title;
    },
    backgroundStyle() {
      // return {
      return this.imageurls[this.currentIndex];
      // 'background-image': `url(${this.imageurls[this.currentIndex]})`
      // };
    },
  },
  destroyed() {
    if (this.swiper) {
      this.swiper.destroy();
    }
    clearInterval(this.bannerText);
  },
  mounted() {
    this.bannerText = setInterval(() => {
      if (this.currentIndex == this.imageurls.length - 1) {
        this.currentIndex = 0;
        return;
      }
      this.currentIndex++;
    }, 5000);

    this.swiper = new Swiper(".swiper-container", {
      autoplay: true, //是否自动播放 true 是  false 否
      slidesPerView: 2, //一排展示几个
      effect: "coverflow", //轮播图的切换效果  coverflow 3D
      centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
      spaceBetween: "-50%", //每个轮播图之间设置距离（单位px）。
      loop: true, // 是否循环播放
      loopAdditionalSlides:6, // 前后各添加几张图片
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      coverflowEffect: {
        rotate: 0, //slide做3d旋转时Y轴的旋转角度
        stretch: 50, //每个slide之间的拉伸值，越大slide靠得越紧。5.3.6 后可使用%百分比
        depth: 100, //slide的位置深度。值越大z轴距离越远，看起来越小。
        modifier: 4, //depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显
        slideShadows: false, //是否开启slide阴影
      },
    });
  },
  methods: {
    newsArticle(index) {
      this.$router.push("/newsxiangqing" + index);
    },
    funRouter() {
      this.$router.push("/keji");
    },
  },
};
// 从Swiper7开始，容器默认类名由'.swiper-container'变更为'.swiper'。
</script>
<style scoped lang="less">
.nav {
  z-index: 9999;
  right: 40px;
  top: 30px;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 120px;

  .nav-li {
    position: relative;
    width: 120px;
    text-align: center;

  }

  .nav-li:hover {
    .nav-children {
      display: block;
    }
  }

  .nav-children {
    position: absolute;
    display: none;
    background: rgba(29, 59, 141, .75);

    li {
      line-height: 42px;
      text-align: center;
      padding: 0 10px;

      :hover {
        color: #01a2e9;
      }
    }
  }

  a {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    color: #fff;
    text-decoration: none;
  }

}

.swiper {
  margin: -4% auto;
  height: 500px;
  text-align: center;
  .swiper-container {
    width: 100%;
    //height: 400px;
    //text-align: center;
    img {
      width: 80%;
      object-fit: cover;
      height: 450px;
    }
  }
}

/* 自动轮播样式 */
.banner-container {
  width: 100%;
  height: 400px;
  /* 轮播图居中 */
  /*margin:1rem auto;*/
  /* 隐藏超出展示容器的内容 */
  overflow: hidden;
  position: relative;
}

.banner-container .banner-img-container {
  width: 6000px;
  height: 400px;
  overflow: hidden;
  position: absolute;
  /* 开启弹性盒，让图片横向排列 */
  display: flex;
  /* animation，@keyframes 搭配使用  run为自定义名称，10s时间*/
  animation: run 10s ease infinite;
}

//img {
//  width: 100%;
//  height: 100%;
//}

/* 动画关键帧 */
/* 以百分比来规定改变发生的时间，或者通过关键词 "from" 和 "to"，等价于 0% 和 100%。0% 是动画的开始时间，100% 动画的结束时间。 */
@keyframes run {
  0%,
  10% {
    /* margin-left: 0; */
    transform: translateX(0);
  }
  20%,
  30% {
    /* margin-left: -1200px;; */
    transform: translateX(-1200px);
  }
  40%,
  50% {
    /* margin-left: -2400px; */
    transform: translateX(-2400px);
  }
  60%,
  70% {
    /* margin-left: -3600px; */
    transform: translateX(-3600px);
  }
  80%,
  90% {
    /* margin-left: -4800px; */
    transform: translateX(-4450px);
  }
  100% {
    /* margin-left: 0; */
    transform: translateX(0);
  }
}

/deep/ .el-menu--horizontal {
  border-bottom: none !important;
}

/deep/ .el-menu-item {
  margin-right: 35px !important;
}

.homepage_wrap {
  width: 100vw;
  /*margin: 0 auto;*/
  /*height: 50vh;*/
  /*background-color: #000;*/
}

.banner_nav {
  width: 100vw;
  height: 65vh;
  /* background-color: #3491e5; */
  background-image: url(../assets/img/bg1.png);
  background-repeat: no-repeat;
  background-size: 100% 80%;
  background-position: center top;
  /* position: relative; */
}

.banner_nav_bg img {
  /* 文本居顶部对齐 */
  vertical-align: top;
}

/* 导航部分 */

.navWrap {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /*  */
  padding-top: 40px;
}

.navWrap_center {
  width: 92vw;
  height: 10vh;
  margin: 0 auto;
  /*background: #fff;*/
  border-radius: 51px;
  /*  */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  justify-items: center;
}

.navWrap_center_img {
  width: 80%;
  display: flex;
  justify-items: center;
  align-items: center;
  margin-left: 2%;
}

.navWrap_center_img img {
  width: 25%;
  margin-top: 16px;
  margin-left: -5%;
}

.navWrap_center_ul {
  /* border:1px solid red; */
  margin-right: 121px;
}

.navWrap_center_ul li {
  display: inline-block;
  height: 98px;
  line-height: 98px;
  /* border-bottom: 4px solid blue; */
  margin-left: 50px;
  padding: 0 5px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  /* line-height: 22px; */
}

.navWrap_center_ul li:hover {
  cursor: pointer;
  color: rgba(35, 51, 127, 1);
  border-bottom: 2px solid rgba(35, 51, 127, 1);
}

/* title */

.banner_title_wrap {
  z-index: 9999;
  width: 100%;
  position: absolute;
  bottom: 30px;
  /* border: 1px solid yellow; */
}

.banner_title_center {
  /* border: 1px solid white; */
  text-align: center;
  /* width: 1200px; */
  margin: 100px auto;
  /* font-size: 50px; */
  font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
  font-weight: bolder;
  color: #ffffff;
  line-height: 124px;
  letter-spacing: 1px;
}

.banner_title_center div {
  padding-top: 50px;
  font-size: 25px;
  font-family: Helvetica;
  line-height: 24px;
  letter-spacing: 4px;
}

.banner_title_center p {
  font-size: 35px;
  font-family: Helvetica;
  line-height: 24px;
  letter-spacing: 4px;
}

.banner_title_center div span {
  margin: 0 18px;
}

/* news */
.news {
  width: 100vw;
  height: 850px;
  background: #f8f8f8;
}

.news_cont {
  width: 1200px;
  margin: 0 auto;
  //margin: -77px auto;
  display: flex;
  justify-content: space-between;
}

.news_cont_left {
  margin-top: 100px;
}

.news_cont_left_img img {
  width: 588px;
  height: 353px;
  margin-top: 41px;
}

.news_cont_left_text {
  padding-top: 27px;
  padding-left: 40px;
  width: 548px;
  height: 150px;
  background-color: #ffffff;
}

.news_cont_left_text h1 {
  font-size: 18px;
  font-weight: 500;
  color: #23337f;
}

.news_cont_left_text p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8f8f8f;
  margin-top: 10px;
}

.news_cont_right {
  width: 588px;
  margin-top: 103px;
}

.news_cont_right_ul ul {
  font-size: 16px;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 22px;
  float: right;
  display: flex;
}

.news_cont_right_ul ul a {
  font-size: 16px;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 22px;
  float: right;
  display: flex;
}

.news_cont_right_ul ul li:hover {
  color: #23337f;
}

.news_cont_right_ul ul span {
  margin: 0 23px 0 24px;
}

.news_cont_right_text {
  width: 588px;
  height: 650px;
  background: #ffffff;
  margin-top: 68px;
}

.news_cont_right_text_item {
  padding: 36px;
}

.news_cont_right_text_item h1 {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}

.news_cont_right_text_item p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8f8f8f;
  line-height: 20px;
}

.news_cont_right_text_hr {
  /* float: right; */
  width: 508px;

  border-bottom: 1px solid #d7d7d7;
}

.news_cont_right_text_hr:nth-of-type(3) {
  /* float: right; */
  width: 508px;

  border-bottom: none;
}

.news_cont_right_text_item2 {
  padding: 24px 40px;
}

.news_cont_right_text_item2 h1 {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}

.news_cont_right_text_item2 p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8f8f8f;
  line-height: 20px;
}

/* 集团产业 */
.GroupIndustry {
  width: 100vw;
  height: 800px;
  background: #ffffff;
}

.GroupIndustry_cont {
  width: 1200px;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
}

.GroupIndustry_cont_left_title {
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444444;
  line-height: 50px;
  margin: 84px 0 40px 0;
}

.GroupIndustry_cont_left_img_f {
  width: 793px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.GroupIndustry_cont_left_img {
  margin-bottom: 24px;

  position: relative;
}

.GroupIndustry_cont_left_img img:first-child {
  width: 384px;
  height: 261px;
  background: linear-gradient(180deg, rgba(0, 100, 255, 0) 0%, #000b6e 100%);
}

.GroupIndustry_cont_left_img2 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.GroupIndustry_cont_left_text {
  position: absolute;
  left: 32px;
  bottom: 18px;
}

.GroupIndustry_cont_left_text p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  margin-top: 10px;
}

.GroupIndustry_cont_left_text span {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

.GroupIndustry_cont_right {
  position: relative;
  margin-top: 98px;
}

.GroupIndustry_cont_right_title {
  float: right;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 22px;
  margin-bottom: 54px;
}

.GroupIndustry_cont_right_img {
  width: 384px;
  height: 546px;
}

.GroupIndustry_cont_right_img2 {
  position: absolute;
  bottom: 24px;
}

.GroupIndustry_cont_right_text {
  position: absolute;
  left: 45px;
  bottom: 45px;
}

.GroupIndustry_cont_right_text p {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 33px;
}

.GroupIndustry_cont_right_text span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}

/* 关于企业 */
.AboutEnterprises {
  width: 100vw;
  height: 856px;
  background-image: url("../assets/img/guanyu_bg.png");
}

.AboutEnterprises_cont {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
}

.AboutEnterprises_cont_top {
  display: flex;
  justify-content: space-between;
}

.AboutEnterprises_cont_top_left {
  margin-top: 143px;
  width: 600px;
}

.AboutEnterprises_cont_top_left > h1 {
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 50px;
}

.AboutEnterprises_cont_top_left p {
  margin-top: 36px;

  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
}

.AboutEnterprises_cont_top_left_btn {
  width: 160px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  margin-top: 81px;
  display: flex;
  align-items: center;
}

.AboutEnterprises_cont_top_left_btn img {
  width: 24px;
  height: 24px;

  margin-left: 8px;
}

.AboutEnterprises_cont_top_left_btn span {
  margin-left: 32px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
}

.AboutEnterprises_cont_top_right {
  position: relative;
  width: 487px;
  height: 517px;
  margin-top: 115px;
}

.AboutEnterprises_cont_top_right img {
  position: absolute;
  z-index: 1;
}

.AboutEnterprises_cont_top_right Div {
  width: 317px;
  height: 408px;
  background: #3491e5;
  position: absolute;
  top: 64px;
  left: -33px;
}

.AboutEnterprises_cont_bottom {
  display: flex;

  margin-top: 80px;
}

.AboutEnterprises_cont_bottom_item {
  margin-right: 70px;
  position: relative;
  width: 247px;
  border-right: 1px solid #ffffff;
  display: flex;
}

.AboutEnterprises_cont_bottom_item3 {
  margin-right: 70px;
  position: relative;
  width: 323px;
  border-right: 1px solid #ffffff;
  display: flex;
}

.AboutEnterprises_cont_bottom_item3 p {
  width: 86px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #b30588;
  line-height: 28px;
}

.AboutEnterprises_cont_bottom_item3 h1 {
  font-size: 60px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 84px;
}

.AboutEnterprises_cont_bottom_item3 span {
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #692020;
  line-height: 24px;
}

.AboutEnterprises_cont_bottom_item4 {
  position: absolute;
  left: 161px;
  bottom: 15px;
}

.AboutEnterprises_cont_bottom_item h1 {
  font-size: 60px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 84px;
}

.AboutEnterprises_cont_bottom_item span {
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #692020;
  line-height: 24px;
}

.AboutEnterprises_cont_bottom_item p {
  width: 86px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #b30588;
  line-height: 28px;
}

.AboutEnterprises_cont_bottom_item2 {
  position: absolute;
  left: 85px;
  bottom: 15px;
}

/* 合作伙伴 */
.CooperativePartner {
  margin-top: -50px;
  width: 100vw;
  height: 650px;
  background-image: url("../assets/img/hezuo_bg.png");
}

.CooperativePartner_cont {
  width: 1200px;
  margin: 0 auto;
}

.CooperativePartner_cont_title {
  text-align: center;
  margin-top: 80px;
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444444;
  line-height: 50px;
}

.CooperativePartner_cont_center {
  display: flex;
  justify-content: space-between;
  width: 365px;
  /*height: 69px;*/

  margin: -80px 0px 0px 150px;
  margin-top: 47px;
}

.CooperativePartner_cont_center_item {
  margin-top: 30px;
  left: 0px;
  /*display: flex;*/
  /*align-items: center;*/
}

.CooperativePartner_cont_center_item:hover {
  border-bottom: 1px solid #3491e5;
}

.CooperativePartner_cont_center_item img {
  width: 40px;
  height: 40px;
}

.CooperativePartner_cont_center_item span {
  line-height: 69px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 33px;
  margin-left: 14px;
}

.CooperativePartner_cont_bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1200px;
  height: 392px;
  margin: 0 auto;
  margin-top: 80px;
}

.CooperativePartner_cont_bottom_logo img {
  width: 227px;
  height: 120px;
  background: #ffffff;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.12);
  border-radius: 9px;
  margin-bottom: 16px;
}

.dropbtn {
  background-color: #fff;
  color: rgb(12, 11, 11);
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  top: 22px;
  left: 394px;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  text-align: center;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 95px;
  min-height: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  color: rgb(6, 6, 6);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
