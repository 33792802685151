<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
<!--			<div>当前位置：首页><span>金融</span></div>-->
		</div>
		<!--  -->
		<div class="NewsDetails">
			<div class="NewsDetails_cont">
				<div class="NewsDetails_cont_title">
					<h1>交通运输部：道路货运运输驾驶员从业资格管理实现便捷化”</h1>
<!--					<div class="NewsDetails_cont_title_span">-->
<!--						<img src="../assets/img/newsDetail/矩形.png" alt="" /><span-->
<!--							>发布时间：2019-11-15&ensp;&ensp;-->
<!--						</span>-->
<!--						<img src="../assets/img/newsDetail/矩形(1).png" alt="" /><span-->
<!--							>浏览次数：12345-->
<!--						</span>-->
<!--					</div>-->
				</div>
				<div class="NewsDetails_cont_text">
					<p>
						“近日，交通运输部、公安部联合部署推进道路货物运输驾驶员从业资格管理改革工作，交通运输部新闻发言人孙文剑在今天举行的新闻发布会上，介绍了改革工作的变化。
					</p>
<!--					<div>“数字经济是全球未来的发展方向</div>-->
					<p>
						“一是考试简洁了，也就是“一次报名、一次培训、一次考试”。会同公安交管部门修订大型货车(B2)、重型牵引挂车(A2)驾驶人科目三安全文明驾驶常识考试内容，将原来的道路货物运输驾驶员从业资格考试安全驾驶理论内容纳入其中(原来这项考试需要在运管局报名、培训、考试)，实现“一次报名、一次培训、一次考试”(A1为大客车驾驶证；B1为中型客车驾驶证)。
					</p>

					<p>
						二是程序减少了，通过一次考试就能直接“申领两证”。申请从事道路货物运输经营的人员，在通过公安交管部门组织的机动车驾驶人考试，取得大型货车(B2)、重型牵引挂车(A2)准驾车型的机动车驾驶证后，凭相应准驾车型的机动车驾驶证即可到当地交通运输主管部门申领从业资格证。
					</p>
					<p>
						三是服务便捷了，线上线下皆可办理。申请从事道路货物运输经营的人员，可在当地政务服务大厅或互联网道路运输便民政务服务系统(部级层面、省级层面皆可，部级层面在部微信小程序也有链接)等进行线下或线上申请，提交身份证、驾驶证等相关证明材料。经审核合格后发放从业资格证证件，纸质证件和电子证件具有同等效力。同时，推动各地进一步提升道路货物运输驾驶员从业资格证件补发、换发、变更、注销、诚信考核等服务事项“跨省通办”的便捷度，为道路货物运输驾驶员提供方便快捷的政务服务。
					</p>
				</div>
				<div class="NewsDetails_cont_img">
					<img
						src="../assets/img/newsDetail/摄图网_401521307_banner.png"
						alt=""
					/>
				</div>
				<!-- 上一篇 下一篇 -->
<!--				<Next ref="childData"></Next>-->
				<div class="NewsDetails_cont_btm">
					<div>相关新闻</div>
<!--					<p>更多 ></p>-->
				</div>
				<div class="NewsDetails_cont_btm2">
					<div class="NewsDetails_cont_btm2_1">
						<div>经营备案”</div>
						<p>2022-05-30</p>
						<p>
							机动车驾驶员培训业务根据经营项目分为普通机动车驾驶员培训、道路运输驾驶员从业资格培训和机动车驾驶员培训教练场经营三类。
						</p>
					</div>
					<div class="NewsDetails_cont_btm2_1" style="background: #f8f8f8">
						<div>培训业务相适应的管理人员”</div>
						<p>2023-06-01</p>
						<p>
							管理人员包括理论教学负责人、驾驶操作训练负责人、教学车辆管理人员、结业考核人员和计算机管理人员等。具体要求按照有关国家标准执行。
						</p>
					</div>
				</div>
			</div>
		</div>

		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
import Next from "../components/NextLastArtcle.vue";
export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
		Next,
	},
	data() {
		return {
			h1: "企业新闻",
			Img1: require("../assets/img/keji/矿山banner1.png"),
			// 这是我在父的组件里 定义的变量 表示当前是数组里第1个页面
			artcleID: 0,

			p1: "更多最新精彩资讯，感受企业魅力",
		};
	},

	methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}
/* 新闻详情 */
.NewsDetails_cont {
	width: 1200px;
	margin: 0 auto;
}
.NewsDetails_cont_title > h1 {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #2b2b2b;
	line-height: 33px;
	margin-top: 78px;
	text-align: center;
}
.NewsDetails_cont_title img {
	vertical-align: middle;
}
.NewsDetails_cont_title_span {
	text-align: center;

	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #a3a3a3;
	line-height: 30px;
	margin-top: 8px;
}
.NewsDetails_cont_text {
	margin-top: 40px;
	border-top: 1px solid #eaeaea;
}
.NewsDetails_cont_text p {
	line-height: 30px;
	width: 1000px;
	margin: 40px 100px;
}
.NewsDetails_cont_text div {
	margin-left: 90px;
	font-size: 24px;
	font-weight: bold;
}
.NewsDetails_cont_img {
	text-align: center;
	margin-bottom: 40px;
}

.NewsDetails_cont_btm {
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
}
.NewsDetails_cont_btm div {
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #222222;
	line-height: 33px;
}
.NewsDetails_cont_btm p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 20px;
}
.NewsDetails_cont_btm2 {
	display: flex;
	justify-content: space-between;
}
.NewsDetails_cont_btm2_1 {
	margin: 32px 0;
	padding: 31px 61px 31px 20px;
	width: 507px;
	height: 123px;
	background: #ffffff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}
.NewsDetails_cont_btm2_1 div {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 25px;
}
.NewsDetails_cont_btm2_1 p {
	margin-top: 10px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 28px;
}
</style>
