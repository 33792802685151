<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
<!--			<div>当前位置：首页><span>金融</span></div>-->
		</div>
		<!--  -->
		<div class="NewsDetails">
			<div class="NewsDetails_cont">
				<div class="NewsDetails_cont_title">
					<h1>河北省交通运输厅 河北省公安厅关于进一步落实驾驶培训监管服务平台与考试系统联网对接工作的通知</h1>
					<div class="NewsDetails_cont_title_span">
<!--						<img src="../../assets/img/newsDetail/矩形.png" alt="" /><span-->
<!--							>发布时间：2019-11-15&ensp;&ensp;-->
<!--						</span>-->
<!--						<img src="../../assets/img/newsDetail/矩形(1).png" alt="" /><span-->
<!--							>浏览次数：12345-->
<!--						</span>-->
					</div>
				</div>
				<div class="NewsDetails_cont_text">
					<p>
						各市(含定州、辛集市)交通运输局，公安局，省道路运输事业发展中心，省公安厅交通管理局：
						为深入贯彻《国务院办公厅转发公安部交通运输部关于推进机动车驾驶人培训考试制度改革意见的通知》(国办发〔2015〕88 号)和《公安部交通运输部关于做好机动车驾驶人培训考试制度改革工作的通知》(公交管〔2016〕50号)精神，落实交通运输部、公安部《关于印发机动车驾驶培训教学与考试大纲的通知》(交运发 〔2022〕36号)和河北省安全生产委员会《关于印发〈河北省安全生 产专项整治三年行动总体方案〉的通知》(冀安委〔2020〕4号)的要求，强化机动车驾驶培训和考试源头监管，进一步夯实道路运输安全基础，实现驾驶培训与考试信息共享，确保培训与考试有效衔接，经省交通运输厅与省公安厅研究，决定进一步落实省驾驶培训监管服务平台(以下简称“驾培监管平台”)与省公安互联网交通安全综合服务管理平台(以下简称“交管服务平台”)联网对接工作(以下简称“联网对接”)。现就有关事项通知如下：
					</p>
					<div>一 、提高思想认识，强化组织领导</div>
					<p>
						各级交通运输主管部门、公安机关要充分认识机动车驾驶人培训考试工作是道路交通安全的重要基础和第一道防线，落实驾培监管平台与交管服务平台联网对接，是加强我省机动车驾驶人培训与考试管理、提高驾驶人培训质量和驾驶技能、从源头上预防和减少道路交通事故、保障道路交通安全的重要举措。 省道路运输事业发展中心和省公安厅交通管理局按照职责负 责联网对接工作的组织、协调、督导和监督检查，及时解决推进中遇到的问题，完善监管措施。 各地交通运输主管部门和公安交通管理部门按照职责负责联网对接工作的实施和日常监管。在工作推进过程中，要加强协调、 密切配合，确保该项工作的顺利实施，取得实效。
						</p>

					<div>二 、推进两个联网，实现驾驶培训与考试工作有效衔接</div>
					<p>
						(一)做好驾培监管平台与驾驶培训机构计时培训系统联网工作。省道路运输事业发展中心组织驾培监管平台与省内各驾驶培训机构计时培训系统的联网对接工作，为各级道路运输管理服务机构实施监管提供支撑。(省道路运输事业发展中心负责，2023年3月30日前完成。)

						(二)落实驾培监管平台与交管服务平台联网对接，实现培训与考试信息共享为避免重复建设，按照“一数一源”的原则，数据对接在驾培监管平台与交管服务平台间实施。省道路运输事业发展中心负责驾培监管平台建设，省公安交通管理局负责提供接口，省道路运输事业发展中心负责将驾驶培训信息写入公安交管服务平台。(2023年4月30日前完成。)

						(三)稳步实施培训信息审核机制按照“总体部署，稳步推进，成熟一地启用一地”的原则，逐步推行培训信息审核机制，公安考试系统在受理学员预约考试时，要 按规定对驾驶培训监管服务平台推送的培训信息数据进行审核验 证，审核合格后方能预约参加考试。(2023年11月30日前各市 完成)

						</p>
					<div>三、加强监管，提升驾驶培训与考试质量</div>

					<p>
						(一)提升驾驶培训质量、确保学时信息真实有效

						一是完善驾培监管平台功能，加强培训监管。省级交通运输主管部门应完善驾培监管平台功能，按照“超时默许”的原则制定计时培训学时审核规则，减少人为因素影响，确保培训和考试过程的顺畅；建立健全计时培训系统服务提供商的服务质量考核机制，确保培训数据真实有效。各地交通运输主管部门要加强对计时培训系统、计时终端设备的监督检查，督促驾培机构落实《机动车驾驶培训教学与考试大纲》，完善培训服务，提升培训质量。

						二是督促驾培机构落实计时培训系统与驾培监管平台的联网。各地交通运输主管部门要落实驾培机构主体责任，督促驾培机构自主选择符合交通运输部《机动车驾驶员计时培训系统平台 技术规范》(交办运函〔2016〕460号)、《机动车驾驶培训网络远程理论教学技术规范》(交通部2017第64号公告)和《机动车驾驶员计时培训系统计时终端技术要求》(JT/T 1302. 1-2019)的计时培训系统和计时终端设备，任何单位和组织不得采取招标等方式干预市场；督促计时培训系统接入省驾培监管平台，实现数据实时传输。

						三是规范培训服务。驾驶培训机构要在经营场所公示收费方 式、收费项目、收费标准、教练员、教学场地、训练道路、培训教学大纲规定的学时和内容、投诉渠道等信息。

						(二)提升考试质量，严把考试关口

						各级公安交管部门要进一步加强对机动车驾驶人考试工作的 监管，认真落实公安部令规范，办理驾驶证业务，要严格受理、考试、发证各环节，杜绝违规违纪问题发生。
						</p>
					<div>四 、加强协调联动，确保联网对接工作规范运行</div>
					<p>
						(一)建立协调联动工作机制。省道路运输事业发展中心和省公安厅交通管理局要建立联网对接工作保障机制，认真组织，结合实际，细化具体措施，确保工作“推得开、用得好，取得实效”。

						(二)建立联合惩戒机制。各级交通运输主管部门要加大监管力度，严厉查处违法违规培训行为。对未按照规定执行教学大纲，伪造、篡改培训数据的驾培机构，监管平台停止接受其数据；公安交管部门应暂停其业务受理，对构成犯罪的，依法追究其法律责任。

						(三)建立应急处置机制。因网络、设备故障造成无法实现共享数据时，各级交通主管部门与公安交通管理部门应及时协调沟通，督促各技术支持单位和部门建立相应快速受理机制，确保学员正常约考。

						(四)做好宣传引导工作。 各级交通运输主管部门和公安交通管理部门要做好宣传引导工作，密切关注行业动态和舆情，及时化解矛盾，确保联网对接工作平稳顺利实施。

					</p>

				</div>
				<div class="NewsDetails_cont_img">
					<img
						src="http://www.hzlitai.com.cn/ueditor/php/upload/image/20230131/1675147572295628.png"
						alt=""
					/>
				</div>
<!--				<Next ref="childData"></Next>-->
				<div class="NewsDetails_cont_btm">
					<div>相关新闻</div>
<!--					<p>更多 ></p>-->
				</div>
				<div class="NewsDetails_cont_btm2">
					<div class="NewsDetails_cont_btm2_1">
						<div>切实提升机动车驾驶培训服务质量”</div>
						<p>2023-08-20</p>
						<p>
							强化质量信誉考核。各省级交通运输主管部门要按照《规定》要求，加快健全完善驾培机构质量信誉考核办法，制定学员满意度评价实施细则，定期组织开展驾培机构质量信誉考核工作。
						</p>
					</div>
					<div class="NewsDetails_cont_btm2_1" style="background: #f8f8f8">
						<div>进一步加强教练员全流程管理”</div>
						<p>2023-08-21</p>
						<p>
							规范教练员聘用管理。各地交通运输主管部门要按照《规定》要求，督促指导驾培机构切实落实教练员管理主体责任，建立健全教练员聘用管理制度和教练员档案，规范教练员聘用要求，及时向交通运输主管部门报送教练员主要信息。
						</p>
					</div>
				</div>
			</div>
		</div>

		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import banner2 from "../../components/banner.vue";
import nav2 from "../../components/nav.vue";
import Next from "../../components/NextLastArtcle.vue";
export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
		Next,
	},
	data() {
		return {
			h1: "企业新闻",
			Img1: require("../../assets/img/keji/矿山banner1.png"),
			artcleID: 1,
			p1: "更多最新精彩资讯，感受企业魅力",
		};
	},
	methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}
/* 新闻详情 */
.NewsDetails_cont {
	width: 1200px;
	margin: 0 auto;
}
.NewsDetails_cont_title > h1 {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #2b2b2b;
	line-height: 33px;
	margin-top: 78px;
	text-align: center;
}
.NewsDetails_cont_title img {
	vertical-align: middle;
}
.NewsDetails_cont_title_span {
	text-align: center;

	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #a3a3a3;
	line-height: 30px;
	margin-top: 8px;
}
.NewsDetails_cont_text {
	margin-top: 40px;
	border-top: 1px solid #eaeaea;
}
.NewsDetails_cont_text p {
	line-height: 30px;
	width: 1000px;
	margin: 40px 100px;
}
.NewsDetails_cont_text div {
	margin-left: 90px;
	font-size: 24px;
	font-weight: bold;
}
.NewsDetails_cont_img {
	text-align: center;
	margin-bottom: 40px;
}
.NewsDetails_cont_footer {
	padding: 0 40px;

	line-height: 68px;
	width: 1120px;
	height: 68px;
	background: #f8f8f8;
	display: flex;
	justify-content: space-between;
}
.NewsDetails_cont_btm {
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
}
.NewsDetails_cont_btm div {
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #222222;
	line-height: 33px;
}
.NewsDetails_cont_btm p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 20px;
}
.NewsDetails_cont_btm2 {
	display: flex;
	justify-content: space-between;
}
.NewsDetails_cont_btm2_1 {
	margin: 32px 0;
	padding: 31px 61px 31px 20px;
	width: 507px;
	height: 123px;
	background: #ffffff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}
.NewsDetails_cont_btm2_1 div {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 25px;
}
.NewsDetails_cont_btm2_1 p {
	margin-top: 10px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 28px;
}
</style>
