<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
<!--		<div class="Crumbs">-->
<!--			<div>当前位置：首页><span>联系我们</span></div>-->
<!--		</div>-->
		<!-- 上部分 -->
		<div class="talk_top">
			<div class="talk_top_cont">
				<div class="talk_top_cont_l">
					<h1>联系我们</h1>
					<p>contact us</p>
				</div>
				<div class="talk_top_cont_c">
					<div class="talk_top_cont_c_t">
						<div class="talk_top_cont_c_t_l">
							<p>工作时间</p>
							<p>周一到周五08:30-18:30</p>
						</div>
						<div class="talk_top_cont_c_t_r">
							<p>热线电话</p>
							<p>TEL 15831112833</p>
						</div>
					</div>

					<div class="talk_top_cont_c_b">
						<div class="talk_top_cont_c_b_l">
							<p>邮箱</p>
							<p>471376282@qq.com</p>
						</div>
						<div class="talk_top_cont_c_b_r">
							<p>地址</p>
							<p>河北省石家庄市桥西区金石工业园区软件大厦A区308室</p>
						</div>
					</div>
				</div>
				<div class="talk_top_cont_r">
					<img src="../assets/xiaocx.jpg" alt="" style="width: 95%;" />
				</div>
			</div>
		</div>
		<!-- 下部分 -->
		<div class="talk_bottom">
			<img src="../assets/gs_map.png" alt="" style="width: 95%;" />
		</div>
		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
	},
	data() {
		return {
			h1: "联系我们",
			Img1: require("../assets/img/keji/lianxiwomenbanner.png"),
			p1: "更多最新精彩资讯，感受企业魅力",
		};
	},
	methods: {},
};
</script>
<style scoped>

.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}
/* 联系我们 */

.talk_top_cont {
	display: flex;
	justify-content: space-between;
	width: 1200px;
	padding-top: 90px;

	margin: 0 auto;
}
.talk_top_cont_l {
	width: 235px;
	height: 134px;
}
.talk_top_cont_l > h1 {
	font-size: 32px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #23337f;
	line-height: 45px;
}
.talk_top_cont_l p {
	width: 235px;
	height: 80px;
	font-size: 48px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #f6f6f6;
	line-height: 40px;
}
.talk_top_cont_c {
	display: flex;
	width: 487px;
}
.talk_top_cont_c {
	font-size: 18px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 25px;
}

.talk_top_cont_c_t_l {
	margin-right: 60px;
}
.talk_top_cont_r {
	width: 117px;
}
.talk_top_cont_r img {
	border: dashed;
}
.talk_top_cont_c_t_r {
	margin-top: 36px;
}
.talk_top_cont_c_b_r {
	margin-top: 36px;
}
.talk_bottom {
	width: 1200px;
	margin: 91px auto;
	margin-bottom: 81px;
}
</style>
