<template>
  <div>
    <div class="wrap">
      <div class="logo">
        <img :src="logoimg" />
      </div>

      <div class="nav">
        <el-menu class="el-menu-demo" mode="horizontal">
          <router-link custom v-slot="{navigate}" to="/">
            <el-menu-item @click="navigate" index="1">
              <a>首页</a>
            </el-menu-item>
          </router-link>
<!--          {-->
<!--          title:"产品线",-->
<!--          url:"/product",-->
<!--          children:[-->
<!--          {-->
<!--          title:"中正易驾",-->
<!--          url:"/product/zhongzhengyijia"-->
<!--          },-->
<!--          {-->
<!--          title:"工单系统",-->
<!--          url:"/product/workOrderSys"-->
<!--          },-->
<!--          {-->
<!--          title:"中正信息科技",-->
<!--          url:"/product/zhongzhengInfo"-->
<!--          },-->
<!--          {-->
<!--          title:"教务系统",-->
<!--          url:"/product/teachingSys"-->
<!--          },-->
<!--          {-->
<!--          title: "活动营销小程序",-->
<!--          url: "/product/activityMarketing"-->
<!--          }-->
<!--          ]-->
<!--          },-->
          <el-submenu index="2">
            <template v-slot:title>产品线</template>
            <router-link custom v-slot="{navigate}" to="/product/zhongzhengyijia">
              <el-menu-item @click="navigate" index="2">
                <a>中正易驾</a>
              </el-menu-item>
            </router-link>
            <router-link custom v-slot="{navigate}" to="/product/workOrderSys">
              <el-menu-item @click="navigate" index="3">
                <a>工单系统</a>
              </el-menu-item>
            </router-link>
            <router-link custom v-slot="{navigate}" to="/product/zhongzhengInfo">
              <el-menu-item @click="navigate" index="4">
                <a>中正信息科技</a>
              </el-menu-item>
            </router-link>
            <router-link custom v-slot="{navigate}" to="/product/teachingSys">
              <el-menu-item @click="navigate" index="5">
                <a>教务系统</a>
              </el-menu-item>
            </router-link>
            <router-link custom v-slot="{navigate}" to="/product/activityMarketing">
              <el-menu-item @click="navigate" index="6">
                <a>活动营销小程序</a>
              </el-menu-item>
            </router-link>
          </el-submenu>
<!--         <el-submenu index="2">-->
<!--            <template slot="title">集团产业</template>-->
<!--            <router-link custom v-slot="{navigate}" to="/keji">-->
<!--              <el-menu-item @click="navigate" index="2">-->
<!--                <a>科技</a>-->
<!--              </el-menu-item>-->
<!--            </router-link>-->
<!--            <router-link custom v-slot="{navigate}" to="/zhijiao">-->
<!--              <el-menu-item @click="navigate" index="3">-->
<!--                <a>职教</a>-->
<!--              </el-menu-item>-->
<!--            </router-link>-->
<!--            <router-link custom v-slot="{navigate}" to="/youjiao">-->
<!--              <el-menu-item @click="navigate" index="4">-->
<!--                <a>幼教</a>-->
<!--              </el-menu-item>-->
<!--            </router-link>-->
<!--            <router-link custom v-slot="{navigate}" to="/dianshang">-->
<!--              <el-menu-item @click="navigate" index="5">-->
<!--                <a>电商</a>-->
<!--              </el-menu-item>-->
<!--            </router-link>-->
<!--            <router-link custom v-slot="{navigate}" to="/jinrong">-->
<!--              <el-menu-item @click="navigate" index="6">-->
<!--                <a>金融</a>-->
<!--              </el-menu-item>-->
<!--            </router-link>-->
<!--          </el-submenu>-->
          <!-- to="/jituangaikuo" -->
          <router-link custom v-slot="{navigate}" to="/">
            <el-menu-item @click="navigate" index="7">
              <a>企业概况</a>
            </el-menu-item>
          </router-link>
<!--          <router-link custom v-slot="{navigate}" to="/xinwen">-->
<!--            <el-menu-item @click="navigate" index="8">-->
<!--              <a>企业新闻</a>-->
<!--            </el-menu-item>-->
<!--          </router-link>-->
          <router-link custom v-slot="{navigate}" to="/lianxi">
            <el-menu-item @click="navigate" index="9">
              <a>联系我们</a>
            </el-menu-item>
          </router-link>
        </el-menu>
        <!-- <div class="dropdown"> -->
        <!-- <button class="dropbtn" @click="funRouter">集团产业</button> -->
        <!-- <div class="dropdown-content"> -->
        <!-- <router-link to="/keji">集团产业</router-link> -->
        <!-- <router-link to="/keji">科技</router-link>
            <router-link to="/zhijiao">职教</router-link>
            <router-link to="/youjiao">幼教</router-link>
            <router-link to="/dianshang">电商</router-link>

            <router-link to="/jinrong">金融</router-link> -->
        <!-- </div> -->
      </div>
      <!-- <ul>
          <li
            v-for="(item, index) in navList"

            :key="index"
            @click="funyuming(item.url)"
          >
            {{ item.title }}
          </li>
        </ul> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      navList: [
        {title: "首页", url: "/"},
        {title: "集团概括", url: "/jituangaikuo"},
        {title: "集团产业", url: "/keji"},
        {title: "集团新闻", url: "/xinwen"},
        // { title: "招聘详情", url: "/zhaopinxiangqing0_0" },
        {title: "联系我们", url: "/lianxi"},
        // { title: "招聘信息", url: "/zhaopinxinxi0" },
      ],
      logoimg: require("../assets/img/logo.png"),
    };
  },
  methods: {
    funyuming(msg) {
      this.$router.push(msg);
    },
    funRouter() {
      this.$router.push("/keji");
    },
  },
};
</script>
<style scoped>
/deep/.el-menu--horizontal {
  border-bottom: none !important;
}
.wrap {
  display: flex;
  justify-items: center;
  align-items: center;
  /* position: relative; */
  /* width: 100vw;
  height: 101px; */
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
}
.logo {
  width: 60%;
}
.wrap > .logo > img {
  margin-left: 20%;
  padding-top: 8px;
  width: 20%;
  height: 20%;
  /* width: 250px; */
  /* position: absolute;
  left: 360px;
  top: 23px;
  width: 250px;
  height: 54px; */
}

.nav {
  width: 556px;
  /* position: absolute; */
  /* left: 900px;
  top: -9px; */
}

.nav ul {
  /* height: 100%; */
  display: flex;
  justify-items: center;
  align-items: center;
  margin-top: -15px;
}

.nav ul li {
  /* line-height: 50%; */
  margin-right: 20px;
  /* width: 80px; */
  /* height: 46px;
  float: left;
  display: flex;
  list-style-type: none;
  color: rgba(68, 68, 68, 1);
  font-size: 16px;


  cursor: pointer; */
}

.nav ul li:hover {
  /* color: rgba(35, 51, 127, 1);
  border-bottom: 2px solid rgba(35, 51, 127, 1); */
}
.dropbtn {
  background-color: #fff;
  color: rgba(68, 68, 68, 1);
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  top: 30px;
  left: 120px;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  z-index: 999;
  text-align: center;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 95px;
  min-height: 120px;
  padding: 16px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  /* color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block; */
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
