<template>
	<div>
		<!-- ---------- -->

		<div class="banner_warp">
			<img class="banner_warp_img" :src="bannerImg" />
			<div class="banner_warp_text">
				<h1>{{ bannerH }}</h1>

				<p>{{ bannerP }}</p>
			</div>
		</div>

		<!-- --------- -->
	</div>
</template>

<script>
export default {
	name: "Banner",
	data() {
		return {};
	},
	props: ["bannerH", "bannerP", "bannerImg"],
};
</script>
<style scoped>
.banner_warp {
	position: relative;
	width: 100vw;
	height: 300px;
	margin: 0 auto;
}
.banner_warp_img {
	width: 100vw;
	z-index: -999;
	position: absolute;
	height: 300px;
}
.banner_warp_text {
	position: absolute;
	left: 845px;
	top: 11px;
}
.banner_warp_text h1 {
	text-align: center;
	padding-top: 112px;

	font-size: 36px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #ffffff;
	line-height: 50px;
}
.banner_warp_text p {
	margin-top: 18px;
	text-align: center;
	font-size: 18px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #ffffff;
	line-height: 25px;
}
</style>
