<template>
  <div class="GroupOverview">
    <daohang></daohang>
    <banner2 :bannerH="h1" :bannerP="P1" :bannerImg="Img1"></banner2>
    <!-- 关于企业 -->
    <div class="about-bg">
      <div class="about" id="about">
        <div class="about-top">
          <div class="about-top-l">
            <span> 关于我们 </span>
            <div>
              <p style="text-indent: 2em">
                河北中正信息科技有限公司成立于2012年，是一家致力于“人、车、网”于一体的智能交通业务的高科技公司，2017年被评为河北省科技型中小企业，2018年被评为国家高新技术企业。公司业务主体为行业用户提供领先的解决方案设计、研发和销售。通过创新融合智能身份认证、移动互联网、视频监控、车联网、云数据中心等技术构建新型智能交通系统，拥有驾培计时管理终端、驾培行业监管服务平台、驾培企业计时平台、互联网驾培公众服务平台及手机APP/微信应用、交通运管与公安交管驾培数据交换平台、道路运输从业人员管理系统等丰富全面的产品体系。
              </p>
              <p style="text-indent: 2em">
                公司由开发部、技术支持部、财务部、法务部、客服部、市场销售部等部门组成，目前拥有员工20多人，均为本科以上学历，产品研发领域涉及政府、国有企业及和私营企业，行业涉及交通、财政、应急救灾、教育等，主要产品有驾校管理应用系统、学时培训计时系统、地震局财务预算管理系统等，其中驾校计时系统成功案例遍布河北省的主要城市，总装车量逾8500辆，通过中正驾培信息系统培训的学员每年达30万人之众。在邢台、秦皇岛、唐山、沧州等多地设有服务机构，拥有在行业多年深耕的产品团队、技术研发力量、广泛分布的营销代理渠道和完善的售后服务体系。
              </p>
            </div>
          </div>
          <div class="about-top-r">
            <div class="img-yinying"></div>
            <img
              src="https://img.js.design/assets/img/613eaa7ba9696d53260506a9.png"
              alt=""
            />
          </div>
        </div>
        <!--				<div class="about-down">-->
        <!--					<div class="item">-->
        <!--						<span class="item-1">30</span>-->
        <!--						<span class="item-2">+</span>-->
        <!--						<span class="item-3">全国校区</span>-->
        <!--					</div>-->
        <!--					<div class="item2">-->
        <!--						<span class="item-1">10</span>-->
        <!--						<span class="item-2">+</span>-->
        <!--						<span class="item2-3">覆盖城市</span>-->
        <!--					</div>-->
        <!--					<div class="item3">-->
        <!--						<span class="item-1">7000</span>-->
        <!--						<span class="item-2">+</span>-->
        <!--						<span class="item3-3">优秀讲师</span>-->
        <!--					</div>-->
        <!--					<div class="item4">-->
        <!--						<span class="item-1">80</span>-->
        <!--						<span class="item-2">万</span>-->
        <!--						<span class="item4-3">服务学生</span>-->
        <!--					</div>-->
        <!--				</div>-->
      </div>
    </div>
    <!-- 企业文化 -->
    <div class="qywh" id="CultureInfo">
      <h1>企业文化</h1>
      <div class="qywh-item">
        <div class="qywh-item1">
          <span><img src="../assets/img/编组 5.png" alt="" /></span>
          <p class="qywh-text">企业定位</p>
          <p class="qywh-text2" style="text-align: center">
            做好驾培服务 争做行业先锋
          </p>
        </div>
        <div class="qywh-item1">
          <span><img src="../assets/img/编组 10.png" alt="" /></span>
          <p class="qywh-text">企业理念</p>
          <p class="qywh-text2 text2-2">正直 善良 感恩 分享</p>
        </div>
        <div class="qywh-item1">
          <span><img src="../assets/img/编组 14.png" alt="" /></span>
          <p class="qywh-text">企业使命</p>
          <p class="qywh-text2">
            &ensp;&ensp;&ensp;科技创新引领产业发展 <br />&ensp;&ensp;&ensp;
            产教融合培育技能人才
          </p>
        </div>
        <div class="qywh-item1">
          <span><img src="../assets/img/编组 15.png" alt="" /></span>
          <p class="qywh-text">企业愿景</p>
          <p class="qywh-text2">
            &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;汇才聚智 赋能产业
          </p>
        </div>
      </div>
    </div>
    <!-- 总裁寄语 -->
    <div class="President">
      <div class="President_content">
        <div class="President-title">
          <p>总裁寄语</p>
        </div>
        <div class="President-text">
          <p>
            选择河北中正合作，成就非凡驾培事业！
            <br />1、河北省第一批通过省监管平台测试的河北本地计时厂商。
            <br />2、深耕河北驾培市场12年，服务口碑好，服务能力强，经验丰富，在全省服务驾校300余所，过去通过计时平台培训合格河北学员达160万人之多。
            <br />3、拥有自主知识产权的计时平台和计时设备，所属产品均有通过符合国家检测机构的测试报告，设备使用范围涉及全国多个省份，市场设备全国拥有量20多万台。
            <!-- <br />4、国家高新技术企业、河北省科技型中小企业称号。 -->
            <br />4、河北驾培市场前三名服务提供商之一，主要有石家庄、邢台、唐山、秦皇岛、沧州等，朋友圈在不断扩张中。
            <br />
            5、沧州市交通局第一家备案厂商。拥有完整的本地服务体系、一脉相承的本地服务人员和技术能力，为您的驾校保驾护航！
          </p>

          <div class="President-text2">
            <!-- <span>执行总裁</span>
            <img src="../assets/img/杨刚.png" alt="" /> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 发展历程 -->
    <div class="DevelopmentHistory" id="history" v-if="false">
      <div class="DevelopmentHistory_cont">
        <h1>发展历程</h1>
        <div class="DevelopmentHistory_cont_img">
          <img
            src="../assets/img/leftjiantou2.png"
            class="DevelopmentHistory_cont_img1"
          />
          <img
            src="../assets/img/rightjiantou.png"
            class="DevelopmentHistory_cont_img2"
          />
        </div>
        <div class="DevelopmentHistory_cont_ul" >
          <ul>
            <p>2010</p>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
          </ul>
          <ul>
            <p>2010</p>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
          </ul>
          <ul>
            <p>2010</p>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
          </ul>
          <ul>
            <p>2010</p>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
          </ul>
          <ul>
            <p>2010</p>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <footer2></footer2>
  </div>
</template>

<script>
import daohang from "../components/nav.vue";
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
export default {
  name: "App",
  data() {
    return {
      h1: "企业概况",
      P1: "欢迎加入我们",
      Img1: require("../assets/img/bannerjituan.png"),
    };
  },
  components: {
    daohang,
    footer2,
    banner2,
  },
  created() {
    this.$nextTick(() => {
      this.toLocal();
    });
  },
  mounted() {
    let _this = this;
    _this.$nextTick(function () {
      window.addEventListener("scroll", _this.handleScroll);
    });
  },
  methods: {
    toLocal() {
      //查找存储的锚点id
      let Id = localStorage.getItem("toId");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
        localStorage.setItem("toId", "");
      }
    },
  },
};
</script>

<style scoped>
.GroupOverview {
  width: 100vw;
  margin: 0 auto;
}
.about {
  height: 730px;
  position: relative;
  margin: 50px 200px;
}

.about-bg {
  background-image: url("../assets/img/蒙版.png");
}

.about-top {
  position: relative;
  display: flex;
}

.about-top-l {
  position: absolute;
  left: 20px;
  top: 85px;
  width: 590px;
  height: 442px;
}

.about-top-l > span {
  font-size: 32px;
}

.about-top-l > div {
  font-size: 16px;
  line-height: 40px;
}

.img-yinying {
  position: absolute;
  top: 58px;
  left: -17px;
  width: 17px;
  height: 408px;
  background: #f8f8f8;
}

.about-top-r {
  position: absolute;
  right: -110px;
}

.about-top-r img {
  width: 487px;
  height: 517px;
}

.about-down {
  position: absolute;
  bottom: 20px;
}

.item {
  top: 236px;
  position: relative;
}

.item2 {
  top: 158px;
  left: 328px;
  position: relative;
}

.item3 {
  top: 79px;
  left: 645px;
  position: relative;
}

.item4 {
  left: 1049px;
  position: relative;
}

.item-1 {
  color: rgba(35, 51, 127, 1);
  font-size: 60px;
}

.item-2 {
  position: absolute;
  color: rgba(35, 51, 127, 1);
  font-size: 16.8px;
}

.item-3 {
  position: absolute;
  left: 88px;
  top: 41px;
  color: rgba(102, 102, 102, 1);
  font-size: 20px;
  width: 100px;
}

.item2-3 {
  position: relative;
  color: rgba(102, 102, 102, 1);
  left: 18px;
  font-size: 20px;
}

.item3-3 {
  position: relative;
  color: rgba(102, 102, 102, 1);
  left: 18px;
  font-size: 20px;
}

.item4-3 {
  position: relative;
  color: rgba(102, 102, 102, 1);
  left: 18px;
  font-size: 20px;
}

.qywh {
  width: 100%;
  height: 598px;
  background-color: rgba(248, 248, 248, 1);
}

.qywh h1 {
  color: rgba(68, 68, 68, 1);
  font-size: 32px;
  line-height: 134px;
  text-align: center;
}

.qywh-item {
  margin: 30px 200px;

  justify-content: space-between;
  display: flex;
}

.qywh-item1 {
  margin-right: 16px;
  position: relative;
  width: 288px;
  height: 348px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.qywh-item1 span {
  position: absolute;
  left: 91px;
  top: 30px;
  display: inline-block;
  border-radius: 100%;
  width: 90px;
  height: 90px;
  background-color: rgba(52, 145, 229, 1);
}

.qywh-item1 span > img {
  border-radius: 100%;
  height: 100%;
  width: 100%;
}

.qywh-text {
  position: absolute;
  top: 138px;
  left: 91px;
  width: 100px;
  color: rgba(68, 68, 68, 1);
  font-size: 24px;
}

.qywh-text2 {
  position: absolute;
  top: 192px;
  left: 8%;
  text-align: center;
  color: rgba(68, 68, 68, 1);
  font-size: 18px;
  line-height: 28px;
}

.text2-2 {
  width: 91px;
  line-height: 30px;
  position: absolute;
  left: 92px;
}

/* 总裁寄语 */
.President {
  width: 100%px;
  height: 801px;
  background-image: url(../assets/img/摄图网_500941399_banner_企业文化背景（非企业商用）.png);
  background-size: 100% 801px;
  padding-top: 84px;
}

.President-title > p {
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 45px;
  text-align: center;
}

.President-text {
  width: 1200px;
  height: 500px;
  margin: 55px auto;
  background: #ffffff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.President-text > p {
  width: 1000px;
  height: 262px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 40px;
  margin: 0 auto;
  padding-top: 35px;
}

.President-text2 {
  height: 50px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 33px;
  text-align: center;
  margin-top: 120px;
  position: relative;
}
.President-text2 > span {
  position: absolute;
  left: 491px;
  top: 15px;
}
.President-text2 > img {
  position: absolute;
}
/* 发展历程 */
.DevelopmentHistory {
  width: 1920;
  height: 527px;
  background-image: url("../assets/img/fazhan_bg.png");
  background-position: 10px -10px;
}
.DevelopmentHistory_cont {
  width: 1200px;
  margin: 0 auto;
}
.DevelopmentHistory_cont_img img {
  margin-top: 61px;
  width: 32px;
  height: 32px;
}
.DevelopmentHistory_cont_img1 {
  float: left;
}
.DevelopmentHistory_cont_img2 {
  float: right;
}
.DevelopmentHistory_cont > h1 {
  text-align: center;
  margin-top: 64px;

  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444444;
  line-height: 45px;
}
.DevelopmentHistory_cont_ul {
  display: flex;
  margin-top: 12px;
  margin-left: 59px;
  justify-content: space-around;
}
.DevelopmentHistory_cont_ul ul p {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 33px;
}

.DevelopmentHistory_cont_ul li {
  margin-top: 8px;
  list-style-type: none;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 24px;
}
</style>
