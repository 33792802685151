export const newList = [
  {
    title: "交通运输部：道路货运运输驾驶员从业资格管理实现便捷化”",
    time: "2023-08-09 11:52",
    text: "近日，交通运输部、公安部联合部署推进道路货物运输驾驶员从业资格管理改革工作，交通运输部新闻发言人孙文剑在今天举行的新闻发布会上，介绍了改革工作的变化"
  },
  {
    title:
      "河北省交通运输厅 河北省公安厅关于进一步落实驾驶培训监管服务平台与考试系统联网对接工作的通知",
    time: "2023-08-24",
    text: "做好驾培监管平台与驾驶培训机构计时培训系统联网工作。省道路运输事业发展中心组织驾培监管平台与省内各驾驶培训机构计时培训系统的联网对接工作，为各级道路运输管理服务机构实施监管提供支撑。(省道路运输事业发展中心负责，2023年3月30日前完成。)"
  },
  {
    title: "关于计时培训系统与省驾培监管服务平台联网对接测试结果的公告",
    time: "2023-09-01",
    text: "根据河北省道路运输事业发展中心《关于推进驾驶员培训与考试有效街接工作的通知》 (冀运车 (2023)9号)要求,自2023年5月23日启动了计时培训系统与省驾培监管服务平台的联网对接测试工作，现将已通过联网对接测试的计时培训系统 (第1批) 公告如下:"
  }
]
export const logoList = [
  { logo: require("../assets/img/zz1.jpg"), name: "河北省科技中小企业" },
  { logo: require("../assets/img/zz2.jpg"), name: "高新技术企业" },
  { logo: require("../assets/img/zz1.jpg"), name: "河北省科技中小企业" },
  { logo: require("../assets/img/zz2.jpg"), name: "高新技术企业" },
  { logo: require("../assets/img/zz1.jpg"), name: "河北省科技中小企业" },
  { logo: require("../assets/img/zz2.jpg"), name: "高新技术企业" },
  { logo: require("../assets/img/zz1.jpg"), name: "河北省科技中小企业" },
  { logo: require("../assets/img/zz2.jpg"), name: "高新技术企业" },
  { logo: require("../assets/img/zz1.jpg"), name: "河北省科技中小企业" },
  { logo: require("../assets/img/zz2.jpg"), name: "高新技术企业" },
]

export const jituanList = [
  {
    jituanimg: require("../assets/img/jituan1.png"),
    jituanimgyinying: require("../assets/img/jituanyinying.png"),
    jituanspan: "金融产业",
    jituanp: "融资租赁  |债券融资  |  信托  |  产业基金",
  },
  {
    jituanimg: require("../assets/img/jituan1.png"),
    jituanimgyinying: require("../assets/img/jituanyinying.png"),
    jituanspan: "金融产业",
    jituanp: "融资租赁  |债券融资  |  信托  |  产业基金",
  },
  {
    jituanimg: require("../assets/img/jituan1.png"),
    jituanimgyinying: require("../assets/img/jituanyinying.png"),
    jituanspan: "金融产业",
    jituanp: "融资租赁  |债券融资  |  信托  |  产业基金",
  },
  {
    jituanimg: require("../assets/img/jituan1.png"),
    jituanimgyinying: require("../assets/img/jituanyinying.png"),
    jituanspan: "金融产业",
    jituanp: "融资租赁  |债券融资  |  信托  |  产业基金",
  },
]
